import { NgxLoggerLevel } from 'ngx-logger';
import {LogLevel} from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version + '-qaqc',
  production: true,
  environment: 'qaqc',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/common',
  azureClientId: 'e3613a13-a6b9-42fe-8f81-52a87b4dc1c4',
  protectedResourceMap: [
    ['https://graph.microsoft.com/v1.0', ['User.Read']],
    ['/api/v1/Environment', null], // unprotected route have null scope
    ['/api/*', ['api://e3613a13-a6b9-42fe-8f81-52a87b4dc1c4/Api.Read']],
    // ... other scopes
  ],
  apiBaseUrl: window.location.origin,
  appInsights: {
    instrumentationKey: null,
  },
};
