import {DateHelper as DateHelperBase} from '@framewerx/common';
import {isValid, parse, parseISO} from "date-fns";

export class DateHelper extends DateHelperBase {

  // Returns true if the two date ranges overlap.
  static IsOverlap(startDateA: Date | string, endDateA: Date | string, startDateB: Date | string, endDateB: Date | string): boolean {
    return !(
      this.GetAsDateOrParseAsDate(endDateA) <= this.GetAsDateOrParseAsDate(startDateB)
      || this.GetAsDateOrParseAsDate(startDateA) >= this.GetAsDateOrParseAsDate(endDateB)
    );
  }

  // Get the input date always as a Date object.
  // Useful if you're not sure if the input is a Date or a string.
  // <br>
  // Note: Input date may simply be returned as-is if it is already a Date object.
  // Otherwise, it is parsed as a date string and returned as a new Date.
  // <br>
  // Supported formats include ISO8601 and partial implementations and yyyy-MM-dd.
  static GetAsDateOrParseAsDate(date: Date | string): Date {
    if (date instanceof Date) return date;

    // Parses ISO8601 and partial implementations
    // https://devdocs.io/date_fns/parseISO
    const isoDate = parseISO(date);
    if (isValid(isoDate)) return isoDate;

    // Fallback conversion (typically not used)

    // Define an array of common date formats to try parsing
    // ISO unicode patterns must be used:
    // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Patterns_AM_PM
    const formats = [
      // Probably not needed but... just in case?
      'yyyy-MM-dd\'T\'HH:mm:ssXXX', // ISO 8601 with time zone offset
      'yyyy-MM-dd\'T\'HH:mm:ss.SSSX', // ISO 8601 with milliseconds and time zone offset
      'yyyy-MM-dd\'T\'HH:mm:ssX', // ISO 8601 with seconds and time zone offset
      'yyyy-MM-dd HH:mm', // yyyy-MM-dd HH:mm
      'yyyy-MM-dd HH:mm:ss', // yyyy-MM-dd HH:mm
      'yyyy-MM-dd', // yyyy-MM-dd
    ];

    // Attempt to parse the input string using each format
    for (const format of formats) {
      const parsedDate = parse(date, format, new Date());

      // Check if the parsed date is valid
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }

    // If none of the formats work, return null or handle the error as needed
    console.error('Could not parse or convert this date: ' + JSON.stringify(date))
    return null;
  }

}
