import {NbMenuItem} from '@nebular/theme';

export const LOGOUT_TITLE: string = 'Logout';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'DRAWINGS',
    group: true,
  },
  {
    title: 'Projects',
    icon: 'briefcase',
    link: '/pages/drawing/projects',
    data: {
      permission: 'view',
      resource: 'usersonly',
    },
  },
  {
    title: 'Drawings List',
    icon: 'file',
    link: '/pages/drawing/drawings-list',
    // home: true,
    data: {
      permission: 'view',
      resource: 'usersonly',
    },
  },
  // {
  //   title: 'Brass In/Out',
  //   icon: 'clock',
  //   link: '/pages/progress/scan-badge-time-card',
  // },
  // {
  //   title: 'Statuses',
  //   icon: 'flag',
  //   link: '/pages/status',
  // },
  // {
  //   title: 'Reports',
  //   icon: 'file-alt',
  //   link: '/pages/report',
  // },
  {
    title: 'UTILITIES',
    group: true,
  },
  // {
  //   title: 'Help',
  //   icon: 'question-circle',
  //   url: 'https://framewerx.atlassian.net/wiki/spaces/DM/overview',
  //   target: '_blank',
  // },
  {
    title: 'Reset App',
    icon: 'redo',
    link: '/pages/dashboard/reset',
  },
];

export const AUTH_ITEMS: NbMenuItem[] = [
  {
    title: 'AUTHENTICATION',
    group: true,
  },
  {
    title: 'Login',
    icon: 'sign-in-alt',
    link: '/pages/auth/login',
    data: {
      permission: 'public',
      resource: 'login',
    },
  },
  {
    title: LOGOUT_TITLE,
    icon: 'sign-out-alt',
    data: {
      permission: 'view',
      resource: 'usersonly',
    },
  },
];
