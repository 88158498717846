import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {DropdownListService} from './dropdown-list.service';
import {StatePersistingService} from '@framewerx/kendo-angular-grid';
import {StateService} from './state.service';
import {UserService} from './users.service';
const SERVICES = [
  CookieService,
  UserService,
  StateService,
  StatePersistingService,
  DropdownListService,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
  exports: [],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
