import { NgModule } from '@angular/core';
import { SeparateCamelCasePipe } from './separate-camel-case.pipe';
import {LengthConverterPipe} from './length-converter.pipe';
import {ShouldDisableActionsPipe} from "./should-disable-actions.pipe";

@NgModule({
  imports: [],
  declarations: [
    SeparateCamelCasePipe,
    LengthConverterPipe,
    ShouldDisableActionsPipe,

  ],
  exports: [
    SeparateCamelCasePipe,
    LengthConverterPipe,
    ShouldDisableActionsPipe,
  ],
  providers: [LengthConverterPipe],
})
export class ApplicationPipesModule {
}
