import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lengthConverter',
})
export class LengthConverterPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static Denominator = 32;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static mm_in = 25.4;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static mm_ft = 304.8;
  private regexImperial = /^(?=.)(((\d+[ \t]*)*?)[fF']?[ \t]*)?[ -]?[ \t]*(((\d+)[ \t]*)*?[ \t]*["iI-]*?[ \t]?((\d+)[ \t]*\/[ \t]*(\d+))?[ \t]*["iI]?)?[ \t]*?$/;

  transform(value: string | null, typeIn: 'metric' | 'imperial' = 'metric', typeOut: 'Metric' | 'Imperial' | 'metric' | 'imperial' = 'Imperial', denominator: number | null = null): any {
    let denom = LengthConverterPipe.Denominator;
    if (denominator) {
      denom = denominator;
    }
    if (typeIn.toLowerCase() === typeOut.toLowerCase()) {
      return `${value}`;
    }
    if (typeIn === 'metric') {
      if (typeOut.toLowerCase() === 'imperial' && value != null) {
        const metric = Number.parseFloat(value);
        const decimalFeet = metric / LengthConverterPipe.mm_ft;
        let ft = Math.floor(decimalFeet);
        let inch = Math.floor((decimalFeet - ft) * 12 + 0.0000001);
        if (inch >= 12) {
          ft += 1;
          inch -= 12;
        }
        const fract = ((decimalFeet - (ft + inch / 12)) * 12);

        if (fract >= (1 / denom)) {
          let frden = denom;
          let frnum = Math.round(fract * frden);
          let lastGCF = 1;
          for (let factor = 2; factor <= frden; factor = factor * 2) {
            if (frnum % factor === 0 && frden % factor === 0) {
              lastGCF = factor;
            }
          }

          frden = frden / lastGCF;
          frnum = frnum / lastGCF;
          if (frden === frnum) {
            frnum = 0;
            frden = denom;
            inch += 1;
            if (inch === 12) {
              inch = 0;
              ft += 1;
            }
          }
          return `${ft}' ${inch}-${frnum}/${frden}"`;
        } else {
          return `${ft}' ${inch}"`;
        }
      }
    } else if (typeIn === 'imperial') {
      if (typeOut.toLowerCase() === 'metric') {
        if (typeof (value) === 'string') {
          const match = this.regexImperial.exec(value);
          if (match) {
            const feet = Number.parseFloat(match[3]);
            const inches = Number.parseFloat(match[5]);
            let inchesFraction = 0;
            if (match[8] && match[9]) {
              const inchNumerator = Number.parseInt(match[8].trim(), 10);
              const inchDenominator = Number.parseInt(match[9].trim(), 10);
              if (inchDenominator !== 0) {
                inchesFraction = inchNumerator / inchDenominator;
              }
            }
            return Math.round((feet * 12 + inches + inchesFraction) * LengthConverterPipe.mm_in * 100) / 100;
          }
        }
      }
    }
    return value;
  }

}
