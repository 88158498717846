import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {ProgressPreloadingStrategy} from './progress-preloading-strategy';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {DrawingViewerComponent} from "./pages/documents/drawing-viewer/drawing-viewer.component";
import {AuthGuard} from "./auth-guard.service";

const routes: Routes = [
  {path: 'pages', loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule)},
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},
  {path: 'auth', component: MsalRedirectComponent},
  // path: 'view-drawing/:id', component: DrawingViewerComponent, canActivate: [AuthGuard] },
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: true,
  preloadingStrategy: ProgressPreloadingStrategy,
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [ProgressPreloadingStrategy],
})
export class AppRoutingModule {
}
