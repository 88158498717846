import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {State, toODataString} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ErrorService} from '../../pages/shared/errors/error.service';
import {DateHelper} from '../utils/date-helper';

@Injectable()
export class DropdownListService extends BehaviorSubject<GridDataResult> {

  public loading: boolean = false;

  protected BASE_ODATA_URL = `${environment.apiBaseUrl}/api/v1/`;

  constructor(
    private http: HttpClient,
    protected errorService: ErrorService,
  ) {
    super(null);
  }

  getIdUrlPart(data: any): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  public query(tableName: string, state: any, params = {}): void {
    this.fetch(tableName, state, null, params)
      .subscribe((x) => super.next(x));
  }

  protected fetch(tableName: string, state: any | State, data: any = null, params = {}): Observable<GridDataResult> {
    const queryStr = `${this.getIdUrlPart(data)}${toODataString(state)}`;
    this.loading = true;

    const newParams = Object.assign({}, params);
    newParams['$count'] = true;
    return this.http
      .get(`${this.BASE_ODATA_URL}${tableName}?${queryStr}`, {params: newParams})
      .pipe(
        map((response) => ({
          data: response['value'].map((e) => DateHelper.ConvertObjectStringDateKeysToDateObjects(e)),
          total: parseInt(response['@odata.count'], 10),
        } as GridDataResult)),
        tap(() => this.loading = false),
      );
  }

}
