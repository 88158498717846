import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ApplicationInsightsService} from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any) {
    const appInsightService = this.injector.get(ApplicationInsightsService);
    if (appInsightService.appInsights != null) {
      appInsightService.appInsights.trackException({exception: error});
    }
    super.handleError(error);
  }

}
