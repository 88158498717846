import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NbAlertModule, NbIconModule} from '@nebular/theme';
import {DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {ExcelModule, GridModule, PDFModule} from '@progress/kendo-angular-grid';
import {NumericTextBoxModule, SliderModule} from '@progress/kendo-angular-inputs';
import {IntlModule} from '@progress/kendo-angular-intl';
import {SchedulerModule} from '@progress/kendo-angular-scheduler';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {ThemeModule} from '../../@theme/theme.module';
import {ApplicationPipesModule} from '../../pipes/application-pipes.module';
import {AdminHeaderContainerComponent} from './admin-header-container/admin-header-container.component';
import {BackComponent} from './back/back.component';
import {DropDownListFilterComponent} from './drop-down-list-filter/drop-down-list-filter.component';
import {DropDownListMenuFilterComponent} from './drop-down-list-filter/drop-down-list-menu-filter.component';
import {ViewErrorsComponent} from './errors/view-errors/view-errors.component';
import {SimpleRouterOutletComponent} from './simple-router-outlet/simple-router-outlet.component';
import {TimeWrapperComponent} from './time-wrapper/time-wrapper.component';
import {KendoAngularGridConfig, KendoAngularGridModule} from '@framewerx/kendo-angular-grid';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {MassEditorComponent} from './mass-editor/mass-editor.component';
import {UpdateMetaTagDialogComponent} from './dialog-containers/update-metatag-dialog/update-metatag-dialog.component';
import {AddMetaTagDialogComponent} from './dialog-containers/add-metatag-dialog/add-metatag-dialog.component';
import {BulkPublishDialogComponent} from './dialog-containers/bulk-publish-dialog/bulk-publish-dialog.component';
import {
  NavigateDocumentSetDialog
} from './dialog-containers/navigate-document-set-dialog/navigate-document-set-dialog.component';
import {
  TransferDrawingDialogComponent
} from './dialog-containers/transfer-drawing-dialog/transfer-drawing-dialog.component';
import {BulkTransferDialogComponent} from './dialog-containers/bulk-transfer-dialog/bulk-transfer-dialog.component';
import {BulkUnPublishDialogComponent} from './dialog-containers/bulk-unpublish-dialog/bulk-unpublish-dialog.component';

export function kendoGridConfigFactory(): KendoAngularGridConfig {
  return {apiRootUrl: `${environment.apiBaseUrl}`} as KendoAngularGridConfig;
}

@NgModule({
  imports: [
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs', // TODO: Implement logging in server https://stackoverflow.com/a/53078568/2557554
      level: environment.loggingLevel,
      // serverLogLevel: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    ThemeModule,
    GridModule,
    ExcelModule,
    PDFModule,
    SliderModule,
    DialogModule,
    DatePickerModule,
    TimePickerModule,
    IntlModule,
    ApplicationPipesModule,
    NbAlertModule,
    SchedulerModule,
    DropDownListModule,
    RouterModule,
    NumericTextBoxModule,
    KendoAngularGridModule,
    ButtonModule,
    NbIconModule,
  ],
  declarations: [
    ViewErrorsComponent,
    BackComponent,
    TimeWrapperComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    SimpleRouterOutletComponent,
    MassEditorComponent,
    UpdateMetaTagDialogComponent,
    AddMetaTagDialogComponent,
    BulkPublishDialogComponent,
    NavigateDocumentSetDialog,
    TransferDrawingDialogComponent,
    BulkTransferDialogComponent,
    BulkUnPublishDialogComponent,
  ],
  providers: [],
  exports: [
    ViewErrorsComponent,
    BackComponent,
    TimeWrapperComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    SimpleRouterOutletComponent,
    MassEditorComponent,
  ],
})
export class SharedModule {
}
