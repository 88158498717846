import {Component, OnDestroy} from '@angular/core';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import {delay, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {StateService} from '../../../@core/data/state.service';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
    <nb-layout [center]="layout.id === 'center-column'" [windowMode]="false">
      <nb-layout-header fixed class="header" *ngIf="!focusMode">
        <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>
      <nb-layout-header subheader *ngIf="!hasInternetAccess">
        <div class='alert alert-danger w-100' role='alert' *ngIf="!hasInternetAccess">
          You do not appear to have an Internet connection!
        </div>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar"
                  tag="menu-sidebar"
                  responsive
                  [end]="sidebar.id === 'end'" *ngIf="!focusMode">
        <!--<nb-sidebar-header *ngIf="currentTheme !== 'corporate'">-->
        <!--<a href="#" class="btn btn-hero-success main-btn">-->
        <!--<i class="ion ion-social-github"></i> <span>Support Us</span>-->
        <!--</a>-->
        <!--</nb-sidebar-header>-->
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <div class="container-fluid">
          <ng-content select="router-outlet"></ng-content>
        </div>
      </nb-layout-column>

      <nb-layout-column start class="small"
                        *ngIf="layout.id === 'two-column' || layout.id === 'three-column' && !focusMode">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column class="small" *ngIf="layout.id === 'three-column' && !focusMode">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-footer fixed class="pt-5" *ngIf="!focusMode">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>

      <!--          <nb-sidebar class="settings-sidebar"-->
      <!--                      tag="settings-sidebar"-->
      <!--                      state="collapsed"-->
      <!--                      fixed-->
      <!--                      [end]="sidebar.id !== 'end'">-->
      <!--              <ngx-theme-settings></ngx-theme-settings>-->
      <!--          </nb-sidebar>-->
    </nb-layout>
  `,
})
export class SampleLayoutComponent implements OnDestroy {

  subMenu: NbMenuItem[] = [
    {
      title: 'PAGE LEVEL MENU',
    },
  ];
  layout: any = {};
  sidebar: any = {};

  currentTheme: string;

  hasInternetAccess: boolean = true;
  status: string;

  focusMode: boolean = false;

  protected destroy$ = new Subject<boolean>();

  constructor(private route: ActivatedRoute,
              protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['focusMode'] != null) {
          this.focusMode = (+params['focusMode']) === 1;
        }
      });

    this.stateService.onLayoutState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.themeService.getJsTheme()
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme) => {
        this.currentTheme = theme.name;
      });

    // ng-connection-service pkg is no longer supported
    // TODO: Find a suitable replacement to it to monitor for disconnected Internet states in order to use the below code
    //
    // this.connectionService.monitor().subscribe((currentState) => {
    //   this.hasInternetAccess = currentState;
    //   if (this.hasInternetAccess) {
    //     this.status = 'ONLINE';
    //   } else {
    //     this.status = 'OFFLINE';
    //   }
    // });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
