/**
 * Docuwerx API
 * An API for Docuwerx Document Management System.
 *
 * The version of the OpenAPI document: v1
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentStoreType = 'AzureContainer';

export const DocumentStoreType = {
    AzureContainer: 'AzureContainer' as DocumentStoreType
};

