import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {IExceptionTelemetry} from '@microsoft/applicationinsights-web';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApplicationInsightsService} from './application-insights.service';
import {ErrorService} from '../../pages/shared/errors/error.service';

export const InterceptSkipErrorHeader = 'X-Skip-Error-Interceptor';
@Injectable({
  providedIn: 'root',
})
export class GlobalHttpErrorInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector, private errorService: ErrorService) {
  }

  // https://stackoverflow.com/a/49047764
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptSkipErrorHeader)) {
      const headers = req.headers.delete(InterceptSkipErrorHeader);
      return next.handle(req.clone({headers}));
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // POST this error information to Azure App Insights
        const loggedError = new Error(error.message);
        loggedError.name = error.name;

        const appInsightException: IExceptionTelemetry = {
          exception: loggedError,
          properties: error.error,
        };

        const appInsightService = this.injector.get(ApplicationInsightsService);
        if (appInsightService.appInsights != null) {
          appInsightService.appInsights.trackException(appInsightException);
        }
        this.errorService.next(error);
        return throwError(() => error);
      }),
    );
  }

}
