import {NbDialogRef} from '@nebular/theme';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'ngx-multi-button-prompt',
  template: `
    <nb-card class="dialog-card nb-layout-column">
      <nb-card-header class="h3">
        {{title}}
        <button (click)="dismiss()"
                type="button"
                class="btn btn-danger float-end">
          <span class="fas fa-times fa-2x"></span>
        </button>
      </nb-card-header>
      <nb-card-body class="h3" style="white-space: pre-line">
        <div *ngIf="bodyHtml" [innerHTML]="bodyHtml | safeHtml"></div>
        <div *ngIf="body">{{body}}</div>
      </nb-card-body>
      <nb-card-footer>
        <div [ngClass]="{'d-grid gap-3': isStackButtons === true}">
          <button *ngIf="button1Text != null" [class]="button1Class" (click)="button1Click()">
            {{button1Text}}
          </button>
          <button *ngIf="button2Text != null" [class]="button2Class" (click)="button2Click()">
            {{button2Text}}
          </button>
          <button *ngIf="button3Text != null" [class]="button3Class" (click)="button3Click()">
            {{button3Text}}
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  `,
  styleUrls: ['./dialog-common.scss'],
})
export class MultiButtonPromptComponent {

  @Input() title: string;
  @Input() body: string = null;
  @Input() bodyHtml: string = null;
  @Input() button1Text: string;
  @Input() button1Result: any = false;
  @Input() button1Class: string = 'btn btn-danger float-start';
  @Input() button2Text: string;
  @Input() button2Result: any = null;
  @Input() button2Class: string = 'btn btn-info float-middle';
  @Input() button3Text: string;
  @Input() button3Result: any = true;
  @Input() button3Class: string = 'btn btn-success float-end';
  @Input() closeResult: any = undefined;
  @Input() isStackButtons: boolean = false;

  constructor(protected dialogRef: NbDialogRef<MultiButtonPromptComponent>) {
  }

  button1Click() {
    this.dialogRef.close(this.button1Result);
  }

  button2Click() {
    this.dialogRef.close(this.button2Result);
  }

  button3Click() {
    this.dialogRef.close(this.button3Result);
  }

  dismiss() {
    this.dialogRef.close(this.closeResult);
  }

}
